import React, { useContext } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import { fg } from '@confluence/feature-gating';
import { AbstractCompanyHubItem } from '@confluence/side-navigation/entry-points/AbstractCompanyHubItem';
import { SPAViewContext } from '@confluence/spa-view-context';

import { PrimaryItem, type PrimaryItemProps } from './PrimaryItem';

/* Revisit styles when transition from site title to space name is done https://hello.jira.atlassian.cloud/browse/CTE-3671 */
const companyHubItemStyles = xcss({
	display: 'none',
	[media.above.md]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
const highlightStyles = (isHighlightedBorderColor: any, isHighlighted: boolean) =>
	xcss({
		borderTopColor: 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderBottomColor: isHighlighted ? `${isHighlightedBorderColor}` : 'transparent',
		borderBlockWidth: 'border.width.indicator',
		borderBlockStyle: 'solid',
	});

export const CompanyHubItem = () => {
	const { frontCoverState, loading } = useContext(SPAViewContext);
	const { theme } = useContext(AppNavigationContext);

	if (loading || !frontCoverState) {
		return null;
	}

	const isHighlightedBorderColor = theme?.mode.primaryButton.selected.borderColor;

	return (
		<AbstractCompanyHubItem navVersion="3">
			{({ companyHubName, isSelected, namedRoute, onClick: onAbstractCompanyHubItemClick }) => {
				let onPrimaryItemClick: PrimaryItemProps['onClick'];

				if (namedRoute && fg('company-hub-pseudo-app')) {
					// `PrimaryItem` opens the link via `RoutesContext` and doesn't support `target`.
					// Preempt `PrimaryItem` by not giving it the link's href `namedRoute`.
					// Then we have to open the link ourselves.
					const url = namedRoute.toUrl();
					namedRoute = undefined;
					onPrimaryItemClick = (e) => {
						onAbstractCompanyHubItemClick();
						if (!e.isDefaultPrevented()) {
							e.preventDefault();
							window.open(url, '_blank', 'noreferrer'); // PrimaryItem opens the link like this
						}
					};
				} else {
					onPrimaryItemClick = onAbstractCompanyHubItemClick;
				}

				return (
					<Box
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
						xcss={[companyHubItemStyles, highlightStyles(isHighlightedBorderColor, isSelected)]}
						{...(isSelected && { testId: 'highlightContainer' })}
					>
						<PrimaryItem
							testId="app-navigation-company-hub-item"
							isHighlighted={false}
							label={companyHubName}
							namedRoute={namedRoute}
							onClick={onPrimaryItemClick}
						/>
					</Box>
				);
			}}
		</AbstractCompanyHubItem>
	);
};
